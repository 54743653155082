//import bugsnagClient from "./bugsnag";

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import qs from "qs";
import socketIO from "socket.io-client";

import isEqual from "lodash/isEqual";
import clone from "lodash/clone";
import CryptoJS from "crypto-js";

import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
// note react-app-polyfills only minimum set, use babel-polyfill if need more

//import registerServiceWorker from './registerServiceWorker';

import { BrowserRouter, Routes, Route, Link, useParams, useLocation } from "react-router-dom";

// local test data for embedded video
//import base64data from "./testdata/base64video";
//window.standaloneVideo = true;
//if (window.standaloneVideo) window.standaloneVideoSource = base64data;
//if (window.standaloneVideo) window.standaloneVideoProjectid = "test";

var config = require("./config");

console.log("BUILD: " + process.env.REACT_APP_BUILD_NUMBER);
if (process && process.env) console.log(process.env);

let io = null;

// analytics array
window.analyticsEvents = [];
window.answers = [];
window.answersSent = [];

//if (!window.standaloneVideo) {
io = socketIO.connect(
  config.socketIo /*, {
    "force new connection": true,
    reconnectionAttempts: "Infinity", //avoid having user reconnect manually in order to prevent dead clients after a server restart
    timeout: 10000, //before connect_error and connect_timeout are emitted.
    
  } */
);
//}

const useInterval = (callback, delay) => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

function ForwardToHome(props) {
  console.log("forwarding to home page");
  window.location = "https://www.automate.video";

  return "";
}

function RootContainer(props) {
  if (window.standaloneVideo) {
    let projectid = window.standaloneVideoProjectid;
    return (
      <BrowserRouter>
        <Route element={<AppContainer projectid={projectid} />} />
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ForwardToHome />} />
        <Route path="/s/:linkid" element={<RouterContainer />} />
        <Route path=":projectid" element={<RouterContainer />} />
      </Routes>
    </BrowserRouter>
  );
}

function RouterContainer(props) {
  let { projectid, linkid } = useParams();

  return <AppContainer embed={props.embed} projectid={projectid} linkid={linkid} />;
}

function AppContainer(props) {
  const [video, setVideo] = useState({});
  const [videourl, setVideourl] = useState(undefined);
  const [projectid, setProjectid] = useState(undefined);
  const [linkid, setLinkid] = useState(undefined);
  const [sessionid, setSessionid] = useState(undefined);
  const [serverConfig, setServerConfig] = useState({});
  const [navigation, setNavigation] = useState({});
  const [session, setSession] = useState({});
  const [participantid, setParticipantid] = useState(null);
  const [link, setLink] = useState({});
  const [settings, setSettings] = useState({
    debug: false,
    nolinks: false,
    noborder: false,
  });
  const [errorMessage, setErrorMessage] = useState(null);

  const [answers, setAnswers] = useState([]);
  const [userSettings, setUserSettings] = useState({});

  const [confirmParticipant, setConfirmParticipant] = useState(false);
  const [anonymousUser, setAnonymousUser] = useState(false);
  const [participantConfirmed, setParticipantConfirmed] = useState(false);
  const [overlaysSet, setOverlaysSet] = useState(false);
  const [playerReady, setPlayerReady] = useState(false);
  const [resetting, setResetting] = useState(false);

  const [updateAnswersFromServer, setUpdateAnswersFromServer] = useState({});

  let location = useLocation();
  let debug = qs.parse(location.search, { ignoreQueryPrefix: true }).debug;
  let sceneNumber = Number(qs.parse(location.search, { ignoreQueryPrefix: true }).scene);
  let nolinks = qs.parse(location.search, { ignoreQueryPrefix: true }).nolinks;
  let anonymous = qs.parse(location.search, { ignoreQueryPrefix: true }).anonymous;
  let embed = qs.parse(location.search, { ignoreQueryPrefix: true }).embed;
  let embediosfs = qs.parse(location.search, { ignoreQueryPrefix: true }).embediosfs;

  useEffect(() => {
    if (anonymous) {
      setParticipantid("anonymous");
      setUserSettings({ ...userSettings, participantid: "anonymous" });
      console.log("participantid setting", participantid);
      setAnonymousUser(true);
    }
  }, []);

  const handleParticipantChange = (event) => {
    event.preventDefault();
    let par = event.currentTarget.participantid.value;
    console.log("participantid", par);
    setParticipantid(par);
    let newUserSettings = { ...userSettings, participantid: par };
    setUserSettings(newUserSettings);
    try {
      localStorage.setItem(linkid + "_usersettings", JSON.stringify(newUserSettings));
    } catch (err) {
      console.error("can not save userSettings to localStorage", err);
    }
  };

  const resetAnswers = (currentTime) => {
    setResetting(true);
    try {
      //localStorage.clear();
      //localStorage.removeItem(linkid + "_usersettings");
      //localStorage.removeItem(linkid + "_answers");
      //window.location.reload(true);
    } catch (err) {
      console.error("can not clear localStorage");
    }

    console.log("answers debug", answers);
    let tempAnswers = clone(answers);
    for (let i = tempAnswers.length - 1; i >= 0; i--) {
      let a = tempAnswers[i];
      let remove = false;
      for (let s of video.scenes)
        for (let ph of s.placeholders)
          if (a.placeholderid === ph.placeholderid && currentTime < s.startTime + 1) {
            setAnswerFormatting(ph.data.answers, null, null);
            remove = true;
          }
      if (remove) tempAnswers.splice(i, 1);
    }

    setAnswers(tempAnswers);
  };

  const handleParticipantConfirmation = (event) => {
    event.preventDefault();
    //console.log(event.currentTarget);
    var activeElement = document.activeElement;
    //console.log(activeElement);
    if (activeElement.type === "submit") {
      if (activeElement.value === "CONTINUE") {
        setParticipantConfirmed(true);
        setConfirmParticipant(false);
      }
      if (activeElement.value === "START AGAIN") {
        //localStorage.clear();
        localStorage.removeItem(linkid + "_usersettings");
        localStorage.removeItem(linkid + "_answers");
        window.location.reload(true);
      }

      //console.log(activeElement.value);
    }
  };

  if (debug === "true" || debug) {
    debug = true;
    //settings.debug = true;
    setSettings({ ...settings, debug: true });
  }

  if (nolinks === "true" || nolinks) setSettings({ ...settings, nolinks: true });

  if (sceneNumber && (navigation == {} || navigation.sceneNumber !== sceneNumber))
    setNavigation({ sceneNumber: sceneNumber });

  if (props.projectid && projectid !== props.projectid) {
    setProjectid(props.projectid);
  }

  if (props.linkid && linkid !== props.linkid) setLinkid(props.linkid);

  // updateAnswersFromServer
  useEffect(() => {
    let payload = updateAnswersFromServer;
    let tempanswers = clone(answers);

    for (let a of tempanswers) {
      for (let an of payload.data.result) {
        if (a.placeholderid === an.placeholderid) {
          if (an.hasOwnProperty("value")) a.saved_value = an.value;

          if (a.saved_value === a.cache_value && a.saved_value != "") {
            if (a.type === "textinput" && !a.saved) {
              document.getElementById(a.buttonid).className = "textareabutton textareabutton-saved";
              document.getElementById(a.buttonid).value = "Saved";
            }
            a.saved = true;
            a.dirty = false;
          }
          //if (a.submit_value === an.value) delete a.submit;
        }
      }
    }
    setAnswers(tempanswers);
    console.log("Answers updated from server");
  }, [updateAnswersFromServer]);

  //console.log("ID", id);
  //console.log("projectid", projectid);
  //console.log("videourl", videourl);
  useEffect(() => {
    console.log("RUNNING message processing");
    io.on("player", (payload) => {
      //console.log(payload);
      switch (payload.type) {
        case "LOAD_CONFIG_SUCCESS":
          setServerConfig(payload.data);
          break;

        case "NEW_VIDEO":
          console.log("new video project received from api");
          setVideo(payload.data);
          break;

        case "UPDATE_VIDEO":
          console.log("video project updated from api");

          setVideo(payload.data);
          if (payload.data?.urls?.videoUrl) setVideourl(payload.data?.urls?.videoUrl);
          if (payload.data?.scenes?.[0]?.gbid) {
            let bytes = CryptoJS.AES.decrypt(
              payload.data.scenes[0].gbid,
              payload.data.scenes[payload.data.scenes.length - 1].sceneid
            );
            console.log(payload.data.scenes[0]);
            setVideourl(bytes.toString(CryptoJS.enc.Utf8));
          }
          break;

        case "UPDATE_SESSION":
          console.log("new session updated from api");

          if (payload.data.errormessage) setErrorMessage(payload.data.errormessage);
          if (payload.data.link) setLink(payload.data.link);
          if (payload.data.session) setSession(payload.data.session);
          if (payload.data.session && payload.data.session.sessionid) setSessionid(payload.data.session.sessionid);
          if (payload.data.video && payload.data.video.projectid) setProjectid(payload.data.video.projectid);
          if (payload.data.video) {
            let tempvideo = payload.data.video;
            /*tempvideo.scenes[1].placeholders.push({
              type: "multiplechoice",
              placeholderid: "placeholderid342342342",
              name: "Which planet is closest to the sun?",
              start_time: 0,
              end_time: 30,
              data: {
                type: "showAnswerOnClick",
                answers: [
                  {
                    answerid: "answerid1",
                    elementid: "element1",
                    correct: false,
                    value: "Mercury",
                    pos: {
                      top: 542,
                      left: 405,
                      width: 409,
                      height: 107,
                    },
                    svg_type: "polygon",
                    svg:
                      '<svg height="210" width="500"><polygon points="100,10 40,198 190,78 10,78 160,198"   <style="fill:lime;stroke:purple;stroke-width:5;fill-rule:nonzero;" /></svg>',
                  },
                  {
                    answerid: "answerid2",
                    elementid: "element2",
                    correct: true,
                    value: "Earth",
                    pos: {
                      top: 666,
                      left: 405,
                      width: 409,
                      height: 107,
                    },
                    svg_type: "polygon",
                    svg:
                      '<svg height="210" width="500"><polygon points="100,10 40,198 190,78 10,78 160,198"   <style="fill:lime;stroke:purple;stroke-width:5;fill-rule:nonzero;" /></svg>',
                  },
                ],
              },
            }); */
            setVideo(tempvideo);
          }

          break;

        case "ANALYTICS_ADD_TO_QUEUE_SUCCESS":
          for (let a of window.analyticsEvents) {
            if (payload.data.analyticsid === a.data.analyticsid) {
              a.delivered = true;
              //console.log("analytics delivered");
            }
          }
          break;

        case "UPDATE_ANSWERS_FROM_SERVER":
          setUpdateAnswersFromServer(payload);
          break;

        case "CREATE_ANSWERS_PDF_SUCCESS":
          //window.open(payload.data.base64);

          var byteArray = Base64Binary.decodeArrayBuffer(payload.data.base64);
          var file = new Blob([byteArray], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          break;
        default:
          break;
      }
    });
  }, []); //only re-run the effect if new message comes in

  const createAnswersPdf = () => {
    io.emit("player", {
      type: "CREATE_ANSWERS_PDF",
      data: { answers: answers, session: session, projectid: projectid, participantid: participantid },
      playerkey: config.playerkey,
    });
    console.log("create answers pdf requested from the server");
  };

  useEffect(() => {
    if (props.projectid && props.projectid.length > 0) {
      console.log("playerkey", config.playerkey);
      io.emit("player", { type: "LOAD_CONFIG", data: {}, playerkey: config.playerkey });
      io.emit("player", { type: "LOAD_PROJECT", data: { projectid: props.projectid }, playerkey: config.playerkey });
      console.log("dispatch load_project");
    }
  }, [props.projectid]);

  useEffect(() => {
    if (props.linkid && props.linkid.length > 0) {
      console.log("linkid", props.linkid);
      console.log("playerkey", config.playerkey);
      io.emit("player", { type: "LOAD_CONFIG", data: {}, playerkey: config.playerkey });
      io.emit("player", { type: "LOAD_LINK", data: { linkid: props.linkid }, playerkey: config.playerkey });
      console.log("dispatch load_link");
    }
  }, [props.linkid]);

  // send analytics events every second

  useInterval(() => {
    for (let a of window.analyticsEvents) {
      let aevent = JSON.parse(JSON.stringify(a));
      aevent.data.participantid = participantid;
      if (!aevent.delivered)
        io.emit("player", Object.assign({ type: "ANALYTICS_ADD_TO_QUEUE", playerkey: config.playerkey }, aevent));
    }
  }, 1000);

  // load answers and usersettings from localStorage if exists
  useEffect(() => {
    if (!anonymous) readAnswersFromLocalStorage();
  }, [linkid]);

  function readAnswersFromLocalStorage() {
    if (linkid) {
      let localStorageAnswers = null;
      let localStorageUserSettings = null;
      try {
        localStorageAnswers = localStorage.getItem(linkid + "_answers");
        let answers2 = JSON.parse(localStorageAnswers);
        if (answers2 && Array.isArray(answers2)) setAnswers(answers2);
        console.log("answers loaded from localStorage", answers2);

        localStorageUserSettings = localStorage.getItem(linkid + "_usersettings");
        let newUserSettings = JSON.parse(localStorageUserSettings);
        if (localStorageUserSettings && typeof newUserSettings === "object") {
          setConfirmParticipant(true);
          setUserSettings(newUserSettings);
          if (newUserSettings.hasOwnProperty("participantid")) setParticipantid(newUserSettings.participantid);
        }
      } catch (err) {
        console.error("can not find answers in localStorage", err);
      }
    }
  }

  useEffect(() => {
    console.log("OVERLAYS SET SETTING FORMATTING");
    if (video && video.scenes && answers)
      for (let a of answers)
        for (let s of video.scenes)
          for (let ph of s.placeholders) {
            if (
              a.placeholderid === ph.placeholderid &&
              ph.data.hasOwnProperty("answers") &&
              ph.data.hasOwnProperty("type")
            ) {
              for (let aa of a.submit_value.answers) setAnswerFormatting(ph.data.answers, aa.answerid, ph.data.type);
              console.log("settings answer formatting");
            }
          }
  }, [overlaysSet]);

  // handle submit buttons
  // add input field submit-button handler

  window.handleMultipleChoiceClick = function (event) {
    var clickEvent = (function () {
      if ("ontouchstart" in document.documentElement === true) return "touchstart";
      else return "click";
    })();
    //event.preventDefault();
    console.log("CLICK", event);
    console.log("event.type", event.type);
    let touch = false;
    if ("ontouchstart" in window) touch = true;
    console.log("ontouchstart found", touch);
    if (
      ("ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) &&
      event.type === "click"
    ) {
      console.log("skipping click on touch devices");
    } else if (event.path && event.path[0]) {
      console.log("found path");
      //console.log("PATH", event.path[2]);
      //console.log("PATH ID", event.path[2].id);
      let elementid = null;
      let answerid = null;
      let placeholderid = null;
      for (let [idx, p] of event.path.entries()) {
        //console.log(p.nodeName);
        if (idx < 5) {
          if (p.nodeName === "FORM") placeholderid = p.id;
          if (p.nodeName === "svg") answerid = p.id;
          if (p.nodeName === "polygon") elementid = p.id;
        }
      }
      window.handleOverlayProcessing(answerid, elementid, placeholderid);
    }
  };

  window.handleOverlayProcessing = function (answerid, elementid, placeholderid) {
    let tempanswers = clone(answers);
    console.log("placeholderid", placeholderid);
    console.log("elementid", elementid);
    console.log("answerid", answerid);

    //answerid = event.path[1].id;
    //placeholderid = event.path[2].id;

    let ph = null;
    let scene = null;
    for (let s of video.scenes)
      for (let p of s.placeholders)
        if (p.placeholderid === placeholderid) {
          scene = s;
          ph = p;
          console.log("found placeholder");
        }

    if (scene && ph) {
      let submit_value = {};
      submit_value.answers = [];
      let submit = false;
      for (let a of tempanswers) {
        if (a.placeholderid === ph.placeholderid) {
          submit_value = a.submit_value;
          a.sceneStartTime = scene.startTime;
          a.phStartTime = ph.start_time;
        }
      }
      if (!submit_value.locked) {
        let updateAnswers = true;
        if (elementid === ph.placeholderid + "-elementid") {
          submit = true;
          submit_value.locked = true;
          console.log("SUBMIT_VALUE", submit_value);
          /*document.getElementById(elementid).style.stroke = "green";
          document.getElementById(elementid).style.fill = "green";
          document.getElementById(elementid).style.fillOpacity = 0.3;
          document.getElementById(elementid).style.strokeOpacity = 0.3; */
          for (let a of submit_value.answers) setAnswerFormatting(ph.data.answers, a.answerid, ph.data.type, submit);
          window.player.currentTime(parseFloat(scene.startTime + ph.data.pauseAt + 0.1));
          window.player.play();
        } else {
          let correctAnswersCount = 0;
          for (let a of ph.data.answers) {
            if (a.correct) correctAnswersCount++;
            if (parseInt(a.correct) < 0) correctAnswersCount = parseInt(a.correct) * -1;
            console.log("correct", a.correct, correctAnswersCount);
          }

          for (let a of ph.data.answers) {
            if (ph.data.type === "showAnswerOnSubmit") {
              submit = false;

              // clicked answer
              if (a.answerid === answerid) {
                // save to submit_value

                let answerFound = false;
                for (let s of submit_value.answers) {
                  if (s.answerid === answerid) {
                    console.log("found existing answer", s, s.selected);
                    //answer already registered
                    answerFound = true;
                    if (s.selected) {
                      s.selected = false;
                      console.log("selected false");
                    } else {
                      s.selected = true;
                      s.selectedTime = new Date();
                      console.log("selected true");
                    }
                  }
                }

                if (!answerFound) {
                  console.log("did not found answer");
                  let newanswer = {
                    answerid: answerid,
                    elementid: a.elementid,
                    selected: true,
                    selectedTime: new Date(),
                    value: a.value,
                    correct: a.correct,
                  };
                  if (a.hasOwnProperty("hotlink") && a.hasOwnProperty("linkTarget") && a.hasOwnProperty("goto_time")) {
                    newanswer.from_scene = a.from_scene;
                    newanswer.goto_scene = a.goto_scene;
                    newanswer.from_scene = a.from_scene;
                    newanswer.goto_time = a.goto_time;
                  }
                  submit_value.answers.push(newanswer);
                }

                // calculate selected
                let selectedCount = 0;

                for (let s of submit_value.answers) if (s.selected) selectedCount++;

                if (correctAnswersCount < selectedCount) {
                  console.log("max answers reached");
                  //updateAnswers = false;
                  let oldestIdx = null;
                  let oldestTime = null;
                  for (let [idx, s] of submit_value.answers.entries()) {
                    if (s.selected)
                      if (oldestTime === null || oldestTime > s.selectedTime) {
                        oldestIdx = idx;
                        oldestTime = s.selectedTime;
                      }
                  }
                  submit_value.answers[oldestIdx].selected = false;
                  //submit_value.answers[oldestIdx].correct = "";
                  //submit_value.answers[oldestIdx].value = "";
                }

                let newsubmit_value_answers = [];
                for (let sa of submit_value.answers) {
                  if (sa.selected) newsubmit_value_answers.push(sa);
                }
                submit_value.answers = newsubmit_value_answers;
              }
            }
            if (ph.data.type === "showAnswerOnClick" || ph.data.type === "showAnswerOnClickAddin") {
              submit = true;
              submit_value.locked = true;

              // clicked answer
              if (a.answerid === answerid) {
                // save to submit_value
                let answerFound = false;
                for (let s of submit_value.answers) {
                  if (s.answerid === answerid) {
                    //answer already registered
                    answerFound = true;
                    window.player.currentTime(parseFloat(scene.startTime + ph.data.pauseAt + 0.1));
                    window.player.play();
                  }
                }
                if (!answerFound) {
                  submit_value.answers.push({
                    answerid: answerid,
                    value: a.value,
                    elementid: a.elementid,
                    correct: a.correct,
                  });
                  for (let a of submit_value.answers)
                    setAnswerFormatting(ph.data.answers, a.answerid, ph.data.type, submit);

                  if (a.hasOwnProperty("hotlink") && a.hasOwnProperty("linkTarget") && a.hasOwnProperty("goto_time")) {
                    window.player.currentTime(parseFloat(scene.startTime + ph.data.pauseAt + 0.1));
                    window.player.play();
                    window.passedScene = a.from_scene;
                    window.goto_scene = a.goto_scene;
                    window.from_scene = a.from_scene;
                    window.goto_time = a.goto_time;
                    console.log("go to slide", window.goto_scene, window.from_scene);
                    /*let delay = scene.endTime - window.player.currentTime();
                    console.log("go to slide", a.linkTarget, window.player.currentTime(), delay * 1000);
                    window.player.play();
                    var goto = setTimeout(() => {
                      console.log("went to slide", window.player.currentTime());
                      window.player.currentTime(parseFloat(a.goto_time));
                      window.player.play();
                    }, delay * 2000);*/
                  } else if (
                    a.hasOwnProperty("hotlink") &&
                    a.hasOwnProperty("linkTarget") &&
                    a.hasOwnProperty("linkType") &&
                    a.linkType === "external"
                  ) {
                    window.player.currentTime(parseFloat(scene.startTime + ph.data.pauseAt + 0.1));
                    window.player.play();
                    window.open(a.linkTarget, "_blank");
                  } else {
                    window.player.currentTime(parseFloat(scene.startTime + ph.data.pauseAt + 0.1));
                    window.player.play();
                  }
                }
              }
            }
          }
        }
        // update answers
        let found = false;
        for (let a of tempanswers) {
          if (a.placeholderid === ph.placeholderid) {
            a.submit = submit;
            a.submit_value = submit_value;
            a.cache_value = submit_value;
            found = true;
          }
        }
        if (!found) {
          tempanswers.push({
            type: "multiplechoice",
            placeholderid: ph.placeholderid,
            name: ph.name,
            /*value: inputs[0].value, */
            submit: submit,
            submit_value: submit_value,
            cache_value: submit_value,
          });
          console.log("PUSHED TO TEMPANSWERS");
        }
        if (updateAnswers) {
          let selectAnswers = clone(ph.data.answers);
          for (let sa of selectAnswers) {
            let isSelected = false;
            for (let a of submit_value.answers) {
              if (sa.answerid == a.answerid) {
                if (a.hasOwnProperty("selected") && a.selected == true) isSelected = true;
              }
            }
            sa.selected = isSelected;
          }
          setAnswerFormatting(selectAnswers, answerid, ph.data.type, submit);

          setAnswers(tempanswers);
        }
      } else {
        console.log("answer locked, value already recorded");
        submit = true;
        for (let a of submit_value.answers) {
          setAnswerFormatting(ph.data.answers, a.answerid, ph.data.type, submit);
        }
        window.player.currentTime(parseFloat(scene.startTime + ph.data.pauseAt + 0.1));
        window.player.play();
      }
    }

    /*
    if (answer) {
      if (answer.correct === true) {
      } else {
      }
    } */
  };
  useEffect(() => {
    saveAnswersToLocalStorage();
  }, [answers]);

  window.handleSubmitButton = function (event) {
    event.preventDefault();
    try {
      //console.log(event.currentTarget);
      let inputs = event.currentTarget.querySelectorAll("textarea");
      //console.log(inputs[0].value);
      //console.log(inputs[0].id);
      let found = false;
      let tempanswers = clone(answers);

      for (let a of tempanswers) {
        if (a.placeholderid === inputs[0].id) {
          /*a.value = inputs[0].value; */
          a.submit = true;
          a.submit_value = inputs[0].value;
          found = true;
        }
      }
      if (!found) {
        tempanswers.push({
          type: "textinput",
          placeholderid: inputs[0].id,
          name: inputs[0].name,
          /*value: inputs[0].value, */
          submit: true,
          submit_value: inputs[0].value,
        });
      }

      setAnswers(tempanswers);
    } catch (err) {
      throw new Error(err.message);
    }
    return false;
  };

  // save form data to answer cache (answers)
  useInterval(() => {
    let tempanswers = clone(answers);

    // saving multiplechoice
    //var forms = document.querySelectorAll("form.textareaclass");

    // saving textinput
    var forms = document.querySelectorAll("form.textareaclass"); //"input"
    for (let f of forms) {
      let textareaid = null;
      let name = null;
      let buttonid = null;
      let value = null;
      let found = false;
      for (let e of f.elements) {
        if (e.type === "textarea") {
          textareaid = e.id;
          name = e.name;
          value = e.value;
        }
        if (e.type === "submit") buttonid = e.id;
      }
      for (let a of tempanswers) {
        if (a.placeholderid === textareaid) {
          a.cache_value = value;
          if (a.hasOwnProperty("saved_value") && a.saved_value !== "" && a.saved_value !== a.cache_value) {
            a.dirty = true;
            a.saved = false;
            document.getElementById(a.buttonid).className = "textareabutton textareabutton-modified";
            document.getElementById(a.buttonid).value = "Update";
          }
          a.buttonid = buttonid;
          found = true;
        }
      }
      if (!found)
        tempanswers.push({
          type: "textinput",
          placeholderid: textareaid,
          name: name,
          buttonid: buttonid,
          cache_value: value,
        });
    }
    setAnswers(tempanswers);

    //console.log("saving changes to player locally");
  }, 2000);

  function saveAnswersToLocalStorage() {
    //save answers to localStorage
    if (linkid) {
      let localStorageAnswers = null;
      try {
        localStorageAnswers = localStorage.getItem(linkid + "_answers");
      } catch (err) {
        console.error("can not find answers in localStorage", err);
      }

      if (JSON.stringify(answers) !== localStorageAnswers) {
        try {
          localStorage.setItem(linkid + "_answers", JSON.stringify(answers));
        } catch (err) {
          console.error("can not save answers to localStorage", err);
        }
      }
    }
  }

  useInterval(() => {
    saveAnswersToLocalStorage();

    // save answers to server
    let answersForSending = [];
    for (let a of answers) {
      let newA = JSON.parse(JSON.stringify(a));
      if (newA.submit) newA.value = newA.submit_value;
      delete newA.submit_value;
      delete newA.cache_value;
      delete newA.saved_value;

      answersForSending.push(newA);
    }

    if (answersForSending.length > 0 && !isEqual(answersForSending, window.answersSent)) {
      //console.log(answersForSending);
      //console.log(window.answersSent);
      io.emit("player", {
        type: "UPDATE_ANSWERS",
        data: {
          projectid: projectid,
          participantid: participantid,
          fpid: window.fpid,
          linkid: linkid,
          sessionid: sessionid,
          result: answersForSending,
        },
        playerkey: config.playerkey,
      });
      window.answersSent = clone(answersForSending);
      console.log("answers sent to api");
    }
  }, 5000);

  /*useEffect(() => {
    dispatch({ type: "NAVIGATION", data: { sceneNumber: sceneNumber } });
  }, [sceneNumber]); */
  /*useEffect(() => {
    dispatch({ type: "SETTINGS", data: settings });
  }, [settings]); */
  //console.log("sessionid", sessionid);
  //console.log("ANSWERS", answers);
  return (
    <App
      video={video}
      config={serverConfig}
      videourl={videourl}
      projectid={projectid}
      linkid={linkid}
      link={link}
      sessionid={sessionid}
      participantid={participantid}
      handleParticipantChange={handleParticipantChange}
      debug={false}
      navigation={navigation}
      settings={settings}
      embed={embed}
      embediosfs={embediosfs}
      errorMessage={errorMessage}
      answers={answers}
      userSettings={userSettings}
      resetAnswers={resetAnswers}
      confirmParticipant={confirmParticipant}
      anonymousUser={anonymousUser}
      handleParticipantConfirmation={handleParticipantConfirmation}
      createAnswersPdf={createAnswersPdf}
      setOverlaysSet={setOverlaysSet}
      overlaysSet={overlaysSet}
      setPlayerReady={setPlayerReady}
    />
  );
}
/*
let embed = false;
if (window.location && window.location.hash) {
  //console.log("WINDOWLOCATION", window.location);
  const hash = window.location.hash;
  if (hash.indexOf("embed") >= 0) {
    embed = true;
  }
} */

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error);
    console.error(errorInfo);
    //logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
//const ErrorBoundary = bugsnagClient.getPlugin("react");
//bugsnagClient.notify(new Error("Test error: " + process.env.REACT_APP_NODE_ENV));

ReactDOM.render(
  <ErrorBoundary>
    <RootContainer />
  </ErrorBoundary>,
  document.getElementById("root")
);

//registerServiceWorker();

/*

const ErrorBoundary = bugsnagClient.getPlugin("react");
//bugsnagClient.notify(new Error("Test error: " + process.env.REACT_APP_NODE_ENV));
ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ForwardToHome />} />
          <Route path=":id" element={<AppContainer embed={embed} />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);
*/

var Base64Binary = {
  _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

  /* will return a  Uint8Array type */
  decodeArrayBuffer: function (input) {
    var bytes = (input.length / 4) * 3;
    var ab = new ArrayBuffer(bytes);
    this.decode(input, ab);

    return ab;
  },

  removePaddingChars: function (input) {
    var lkey = this._keyStr.indexOf(input.charAt(input.length - 1));
    if (lkey == 64) {
      return input.substring(0, input.length - 1);
    }
    return input;
  },

  decode: function (input, arrayBuffer) {
    //get last chars to see if are valid
    input = this.removePaddingChars(input);
    input = this.removePaddingChars(input);

    var bytes = parseInt((input.length / 4) * 3, 10);

    var uarray;
    var chr1, chr2, chr3;
    var enc1, enc2, enc3, enc4;
    var i = 0;
    var j = 0;

    if (arrayBuffer) uarray = new Uint8Array(arrayBuffer);
    else uarray = new Uint8Array(bytes);

    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

    for (i = 0; i < bytes; i += 3) {
      //get the 3 octects in 4 ascii chars
      enc1 = this._keyStr.indexOf(input.charAt(j++));
      enc2 = this._keyStr.indexOf(input.charAt(j++));
      enc3 = this._keyStr.indexOf(input.charAt(j++));
      enc4 = this._keyStr.indexOf(input.charAt(j++));

      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      uarray[i] = chr1;
      if (enc3 != 64) uarray[i + 1] = chr2;
      if (enc4 != 64) uarray[i + 2] = chr3;
    }

    return uarray;
  },
};

function setAnswerFormatting(answers, selectedAnswerid, answerType, submit = false) {
  for (let a of answers) {
    if (answerType === "showAnswerOnClickAddin") {
      // clicked answer
      if (a.answerid === selectedAnswerid) {
        // change colors
        if (a.correct === true) {
          let elem = document.getElementById(a.answerid);
          let checked = elem.querySelector("#quizz_checked");
          if (checked) checked.setAttribute("opacity", 1);
          //let correct = elem.querySelector("#quizz_checkcorrect1");
          //if (correct) correct.setAttribute("opacity", 1);
          let correct = elem.querySelectorAll("[id^=quizz_checkcorrect]");
          for (let c of correct) c.setAttribute("opacity", 1);
          //console.log("checked", checked);
          console.log("ELEMENT", elem);
        } else {
          let elem = document.getElementById(a.answerid);
          let checked = elem.querySelector("#quizz_checked");
          if (checked) checked.setAttribute("opacity", 1);

          let wrong = elem.querySelectorAll("[id^=quizz_checkwrong]");
          for (let w of wrong) w.setAttribute("opacity", 1);
        }
      } else {
        if (a.correct === true) {
        } else {
        }
      }
    } else if (answerType === "showAnswerOnSubmit") {
      console.log("processing showAnswersOnSubmit", submit, a);
      if (submit) {
        if (a.selected) {
          if (a.correct === true) {
            document.getElementById(a.elementid).style.stroke = "green";
            document.getElementById(a.elementid).style.fill = "green";
            document.getElementById(a.elementid).style.fillOpacity = 0.3;
            document.getElementById(a.elementid).style.strokeOpacity = 0.3;
          } else if (a.correct === false) {
            document.getElementById(a.elementid).style.stroke = "red";
            document.getElementById(a.elementid).style.fill = "red";
            document.getElementById(a.elementid).style.fillOpacity = 0.3;
            document.getElementById(a.elementid).style.strokeOpacity = 0.3;
          } else {
            document.getElementById(a.elementid).style.stroke = "grey";
            document.getElementById(a.elementid).style.fill = "grey";
            document.getElementById(a.elementid).style.fillOpacity = 0.3;
            document.getElementById(a.elementid).style.strokeOpacity = 0.3;
          }
        } else {
          if (a.correct === true) {
            document.getElementById(a.elementid).style.stroke = "green";
            document.getElementById(a.elementid).style.strokeOpacity = 0.3;
          } else if (a.correct === false) {
            document.getElementById(a.elementid).style.stroke = "red";
            document.getElementById(a.elementid).style.strokeOpacity = 0.3;
          } else {
            document.getElementById(a.elementid).style.stroke = "grey";
            document.getElementById(a.elementid).style.strokeOpacity = 0.3;
          }
        }
      } else if (a.selected) {
        console.log("setting selected", a);
        document.getElementById(a.elementid).style.stroke = "grey";
        document.getElementById(a.elementid).style.fill = "grey";
        document.getElementById(a.elementid).style.fillOpacity = 0.3;
        document.getElementById(a.elementid).style.strokeOpacity = 0.3;

        if (a.hasOwnProperty("goto_time")) {
          window.passedScene = a.from_scene;
          window.goto_scene = a.goto_scene;
          window.from_scene = a.from_scene;
          window.goto_time = a.goto_time;
          console.log("go to slide", window.goto_scene, window.from_scene);
          /*let delay = scene.endTime - window.player.currentTime();
            console.log("go to slide", a.linkTarget, window.player.currentTime(), delay * 1000);
            window.player.play();
            var goto = setTimeout(() => {
              console.log("went to slide", window.player.currentTime());
              window.player.currentTime(parseFloat(a.goto_time));
              window.player.play();
            }, delay * 2000);*/
        } else if (
          a.hasOwnProperty("hotlink") &&
          a.hasOwnProperty("linkTarget") &&
          a.hasOwnProperty("linkType") &&
          a.linkType === "external"
        ) {
          window.open(a.linkTarget, "_blank");
        }
      } else {
        document.getElementById(a.elementid).style.fillOpacity = 0;
        document.getElementById(a.elementid).style.strokeOpacity = 0;
      }
    } else if (answerType === "showAnswerOnClick") {
      // clicked answer
      if (a.answerid === selectedAnswerid) {
        // change colors
        console.log("element clicked", a);
        if (a.correct === true) {
          document.getElementById(a.elementid).style.stroke = "green";
          document.getElementById(a.elementid).style.fill = "green";
          document.getElementById(a.elementid).style.fillOpacity = 0.3;
          document.getElementById(a.elementid).style.strokeOpacity = 0.3;
        } else if (a.correct === false) {
          document.getElementById(a.elementid).style.stroke = "red";
          document.getElementById(a.elementid).style.fill = "red";
          document.getElementById(a.elementid).style.fillOpacity = 0.3;
          document.getElementById(a.elementid).style.strokeOpacity = 0.3;
        } else {
          document.getElementById(a.elementid).style.stroke = "grey";
          document.getElementById(a.elementid).style.fill = "grey";
          document.getElementById(a.elementid).style.fillOpacity = 0.3;
          document.getElementById(a.elementid).style.strokeOpacity = 0.3;
        }
      } else {
        if (a.correct === true) {
          document.getElementById(a.elementid).style.stroke = "green";
          document.getElementById(a.elementid).style.strokeOpacity = 0.3;
        } else if (a.correct === false) {
          document.getElementById(a.elementid).style.stroke = "red";
          document.getElementById(a.elementid).style.strokeOpacity = 0.3;
        } else {
          document.getElementById(a.elementid).style.stroke = "grey";
          document.getElementById(a.elementid).style.strokeOpacity = 0.3;
        }
      }
    } else {
      console.log("resetting ", a.elementid);
      document.getElementById(a.elementid).style.strokeOpacity = 0;
      document.getElementById(a.elementid).style.fillOpacity = 0;
    }
  }
}
